@import url("https://fonts.googleapis.com/css?family=Space+Mono:400,700&display=swap");

body,
html,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Space Mono', monospace;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 23px;
  letter-spacing: normal;
}

input, select, textarea, button{
  font-family: inherit;
}

h1 {
  font-size: 40px;
  line-height: 50px;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

@media (max-width: 640px) {
  h1 {
    font-size: 23px;
    line-height: 30px;
  }
}
