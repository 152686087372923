.root .header {
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: 60px;
  background: #fff;
}

.root .blueBackground {
  position: absolute;
  top: 0;
  left: 0;
  background: #283580;
  width: 100%;
  height: 250px;
  z-index: -1;
}

.root .content {
  padding: 50px 25px;
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
}

.root .content > * {
  margin-bottom: 40px;
}

.root .content > :last-child {
  margin-bottom: 0;
}

.root .content img {
  width: 100%;
}

@media (min-width: 640px) {
  .root .header {
    height: 75px;
  }

  .root .blueBackground {
    height: 400px;
  }
}

@media (min-width: 1024px) {
  .root .content {
    padding: 50px 0;
  }
}
