@import url(https://fonts.googleapis.com/css?family=Space+Mono:400,700&display=swap);
body,
html,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Space Mono', monospace;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 23px;
  letter-spacing: normal;
}

input, select, textarea, button{
  font-family: inherit;
}

h1 {
  font-size: 40px;
  line-height: 50px;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

@media (max-width: 640px) {
  h1 {
    font-size: 23px;
    line-height: 30px;
  }
}

.LandingPage_root__3dTRs .LandingPage_header__2Vs2H {
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: 60px;
  background: #fff;
}

.LandingPage_root__3dTRs .LandingPage_blueBackground__2tlh3 {
  position: absolute;
  top: 0;
  left: 0;
  background: #283580;
  width: 100%;
  height: 250px;
  z-index: -1;
}

.LandingPage_root__3dTRs .LandingPage_content__2ooXX {
  padding: 50px 25px;
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
}

.LandingPage_root__3dTRs .LandingPage_content__2ooXX > * {
  margin-bottom: 40px;
}

.LandingPage_root__3dTRs .LandingPage_content__2ooXX > :last-child {
  margin-bottom: 0;
}

.LandingPage_root__3dTRs .LandingPage_content__2ooXX img {
  width: 100%;
}

@media (min-width: 640px) {
  .LandingPage_root__3dTRs .LandingPage_header__2Vs2H {
    height: 75px;
  }

  .LandingPage_root__3dTRs .LandingPage_blueBackground__2tlh3 {
    height: 400px;
  }
}

@media (min-width: 1024px) {
  .LandingPage_root__3dTRs .LandingPage_content__2ooXX {
    padding: 50px 0;
  }
}

/* Defaults */
.Button_button__1TIA1 {
  display: inline-block;
  background: transparent;
  border-radius: 24.5px;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 11px 30px;
}

.Button_fullWidth__2SLy3 {
  display: block;
}

.Button_short__2IOZQ {
  padding: 9px 30px;
}

.Button_disabled__3a9g2 {
  pointer-events: none;
  cursor: default;
  opacity: 0.32;
}

.Button_defaultLinksDecoration__2dDy4 {
  text-decoration: none;
}

.Button_button__1TIA1,
.Button_button__1TIA1:link,
.Button_button__1TIA1:visited,
.Button_button__1TIA1:active,
.Button_button__1TIA1:hover {
  text-decoration: none;
}

.Button_button__1TIA1:hover {
  -webkit-filter: invert(100%);
          filter: invert(100%);
}

.Button_container__2vLEW {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Button_container__2vLEW > svg,
.Button_container__2vLEW > img {
  max-width: 25px;
  max-height: 25px;
}

.Button_container__2vLEW > svg,
.Button_container__2vLEW > img {
  margin-right: 15px;
}

/* Blue color */
.Button_blue__iJrb_ {
  border: solid 1.5px #283580;
}

.Button_blue__iJrb_,
.Button_blue__iJrb_:link,
.Button_blue__iJrb_:visited,
.Button_blue__iJrb_:active {
  background: #283580;
  color: #fff;
}

.Button_blue__iJrb_:hover {
  border: solid 1.5px #FFE4A7;
  background: #FFE4A7;
  color: #283580;
  -webkit-filter: invert(0);
          filter: invert(0);
}

/* Red color */
.Button_red__pn18d {
  border: solid 1.5px #d94b2f;
}

.Button_red__pn18d,
.Button_red__pn18d:link,
.Button_red__pn18d:visited,
.Button_red__pn18d:active {
  background: #d94b2f;
  color: #fff;
}

.Button_red__pn18d:hover {
  border: solid 1.5px #FFE4A7;
  background: #FFE4A7;
  color: #283580;
  -webkit-filter: invert(0);
          filter: invert(0);
}

/* White color */
.Button_white__3RCnY {
  border: solid 1.5px #283580;
}

.Button_white__3RCnY,
.Button_white__3RCnY:link,
.Button_white__3RCnY:visited,
.Button_white__3RCnY:active {
  background: #fff;
  color: #283580;
}

.Button_white__3RCnY:hover {
  border: solid 1.5px #283580;
  background: #283580;
  color: #fff;
  -webkit-filter: invert(0);
          filter: invert(0);
}

/* Yellow color */
.Button_yellow__2o7BO {
  border: solid 1.5px #fff4a7;
}

.Button_yellow__2o7BO,
.Button_yellow__2o7BO:link,
.Button_yellow__2o7BO:visited,
.Button_yellow__2o7BO:active {
  background: #fff4a7;
  color: #283580;
}

.Button_yellow__2o7BO:hover {
  border: solid 1.5px #fff;
  background: #fff;
  color: #283580;
  -webkit-filter: invert(0);
          filter: invert(0);
}

/* Transparent color */
.Button_transparent__zau1v {
  padding: 0;
}

.Button_transparent__zau1v,
.Button_transparent__zau1v:link,
.Button_transparent__zau1v:visited,
.Button_transparent__zau1v:active {
  background: transparent;
  color: #283580;
}

.Button_transparent__zau1v:hover {
  color: #d94b2f;
  -webkit-filter: invert(0);
          filter: invert(0);
}

