/* Defaults */
.button {
  display: inline-block;
  background: transparent;
  border-radius: 24.5px;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 11px 30px;
}

.fullWidth {
  display: block;
}

.short {
  padding: 9px 30px;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.32;
}

.defaultLinksDecoration {
  text-decoration: none;
}

.button,
.button:link,
.button:visited,
.button:active,
.button:hover {
  text-decoration: none;
}

.button:hover {
  filter: invert(100%);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container > svg,
.container > img {
  max-width: 25px;
  max-height: 25px;
}

.container > svg,
.container > img {
  margin-right: 15px;
}

/* Blue color */
.blue {
  border: solid 1.5px #283580;
}

.blue,
.blue:link,
.blue:visited,
.blue:active {
  background: #283580;
  color: #fff;
}

.blue:hover {
  border: solid 1.5px #FFE4A7;
  background: #FFE4A7;
  color: #283580;
  filter: invert(0);
}

/* Red color */
.red {
  border: solid 1.5px #d94b2f;
}

.red,
.red:link,
.red:visited,
.red:active {
  background: #d94b2f;
  color: #fff;
}

.red:hover {
  border: solid 1.5px #FFE4A7;
  background: #FFE4A7;
  color: #283580;
  filter: invert(0);
}

/* White color */
.white {
  border: solid 1.5px #283580;
}

.white,
.white:link,
.white:visited,
.white:active {
  background: #fff;
  color: #283580;
}

.white:hover {
  border: solid 1.5px #283580;
  background: #283580;
  color: #fff;
  filter: invert(0);
}

/* Yellow color */
.yellow {
  border: solid 1.5px #fff4a7;
}

.yellow,
.yellow:link,
.yellow:visited,
.yellow:active {
  background: #fff4a7;
  color: #283580;
}

.yellow:hover {
  border: solid 1.5px #fff;
  background: #fff;
  color: #283580;
  filter: invert(0);
}

/* Transparent color */
.transparent {
  padding: 0;
}

.transparent,
.transparent:link,
.transparent:visited,
.transparent:active {
  background: transparent;
  color: #283580;
}

.transparent:hover {
  color: #d94b2f;
  filter: invert(0);
}
